<template>
  <div>

    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text squared>
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama Pasien / Orang Tua Pasien"
            @keyup="filterByName"></b-form-input>
        </b-input-group>
      </div>
    </div>
    <!-- Table -->
    <b-table
      striped
      hover
      class="mt-3"
      :items="items"
      :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? purpose == 'master' ? '30%' : '' : '' }" />
      </template>
      <template #cell(actions)='data'>
        <template v-if="purpose == 'master'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="$router.push({ path: '/patient/detail/' + data.item.id })"> Info </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            @click="$router.push({ path: '/patient/edit/' + data.item.id })"> Edit </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="$router.push({ path: '/patient/history/' + data.item.id })"> Riwayat </b-button>
          <b-button
            size="sm"
            class="mr-1 btn-success"
            @click="$router.push({ path: '/patient/payment/' + data.item.id })">
            Pembayaran
          </b-button>
          <b-button
            size="sm"
            class="btn-danger"
            @click="deleteData(data.item.id)"> Hapus </b-button>
        </template>
        <template v-if="purpose == 'modal'">
          <b-button
            size="sm"
            class="mr-1 btn-info"
            @click="choosePatient({ id: data.item.id, name: data.item.name + ` (${data.item.mobile_phone})` })"> Pilih </b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"></b-pagination>

  </div>
</template>

<script>

import { debouncer } from "@/core/modules/Helper.js"
import module from '@/core/modules/CrudModule.js'

export default {

  props: {
    purpose: String
  },

  data() {
    return {
      // Filter
      filter: {
        name: '',
        birt_date: ''
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 0,
      // Table Head
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "id_card_number",
          label: "Nomor Kartu",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          // sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "gender",
          label: "Jenis Kelamin",
          sortable: true,
        },
        {
          key: "mobile_phone",
          label: "Telepon Seluler",
          sortable: true,
        },
        {
          key: "parent_name",
          label: "Orang Tua",
          sortable: true,
        },

        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: null,
    }
  },

  methods: {

    async pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      await this.pagination()
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&birt_date=${this.filter.birt_date}`
      let response = await module.paginate('patient-select', `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`)
      let pagination = response.meta.pagination
      console.log(response.meta)
      this.items = response.data
      this.totalRows = pagination.total
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete('patients/' + id)
      // If Deleted
      if (result) {
        this.pagination()
      }
    },

    filterByName() {
      debouncer(() => { this.pagination() }, 500)
    },

    filterByBirtDate() {
      this.pagination()
    },

    choosePatient(value) {
      this.$emit('choosePatient', value)
    }

  },

  mounted() {
    // Get Data
    this.pagination()
  },

}
</script>

<style></style>