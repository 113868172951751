<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Pemakai Bangsal'">
          <template v-slot:body>
            <div>
              <b-form>
                <!-- Input Bed -->
                <b-form-group
                  id="input-group-bed"
                  label="Pilih Bangsal:"
                  label-for="input-bed"
                >
                  <treeselect
                    v-model="form.bed_id"
                    :multiple="false"
                    :options="beds"
                  />
                  <small class="text-danger">{{ error.bed_id }}</small>
                </b-form-group>

                <!-- Input Patient -->
                <!-- <b-form-group
                  id="input-group-patient"
                  label="Pilih Pasien:"
                  label-for="input-patient"
                >
                  <treeselect
                    v-model="form.patient_id"
                    :multiple="false"
                    :options="patients"
                    required
                  />
                  <small class="text-danger">{{ error.patient_id }}</small>
                </b-form-group> -->

                <!-- Input Modal Trigger -->
                <b-form-group
                  id="input-group-trigger-modal-patient"
                  label="Pasien:"
                  label-for="input-trigger-modal-patient"
                >
                  <b-form-input
                    id="input-trigger-modal-patient"
                    v-model="display.patient_name"
                    placeholder="Pilih Pasien"
                    readonly
                    @click="$bvModal.show('modal-patient')"
                  >
                  </b-form-input>
                  <small class="text-danger">{{ error.patient_id }}</small>
                </b-form-group>

                <!-- Input Alloted Time -->
                <!-- <b-form-group
                  id="input-group-alloted-time"
                  label="Waktu Masuk:"
                  label-for="input-alloted-time"
                >
                  <b-form-datepicker
                    id="input-alloted-time"
                    v-model="form.date_in"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ error.date_in }}</small>
                </b-form-group> -->

                <div class="row">
                  <div class="col-md-6 col-lg-6">
                    <b-form-group
                      id="input-group-date"
                      label="Tanggal Masuk:"
                      label-for="input-date-end"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-form-datepicker
                            locale="id"
                            placeholder="Tanggal"
                            v-model="form.date_in"
                            button-only
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-date-end"
                          v-model="form.date_in"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          @input="formaterDate"
                        ></b-form-input>
                      </b-input-group>
                      <small class="text-danger">{{ error.date_in }}</small>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-lg-6">
                    <b-form-group
                      id="input-group-date"
                      label="Tanggal Keluar:"
                      label-for="input-date-end"
                    >
                      <b-input-group>
                        <b-input-group-prepend>
                          <b-form-datepicker
                            locale="id"
                            placeholder="Tanggal"
                            v-model="form.date_out"
                            button-only
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-prepend>
                        <b-form-input
                          id="input-date-end"
                          v-model="form.date_out"
                          type="text"
                          placeholder="YYYY-MM-DD"
                          @input="formaterDateOut"
                        ></b-form-input>
                      </b-input-group>
                      <small class="text-danger">{{ error.date_out }}</small>
                    </b-form-group>
                  </div>
                </div>

                <!-- Input Discharge Time -->
                <!-- <b-form-group
                  id="input-group-discharge-time"
                  label="Waktu Keluar:"
                  label-for="input-discharge-time"
                >
                  <b-form-datepicker
                    id="input-discharge-time"
                    v-model="form.date_out"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ error.date_out }}</small>
                </b-form-group> -->

                <!-- Button -->
                <b-button variant="primary" @click="formOnSubmit"
                  >Simpan</b-button
                >
                <b-button
                  class="ml-2"
                  variant="default"
                  @click="$router.push('/beds/allotment')"
                >
                  Cancel
                </b-button>
              </b-form>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <ModalPatient @chosenPatient="setPatient" @submitedPatient="setPatient" />
  </div>
</template>

<script>
import ModalPatient from "@/component/general/ModalPatientBed.vue";
import Card from "@/view/content/Card.vue";
import Form from "@/component/beds/Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  data() {
    return {
      display: {
        patient_name: "",
      },
      // Form
      form: {
        bed_id: null,
        patient_id: null,
        date_in: null,
        date_out: null,
      },
      error: {
        bed_id: "",
        patient_id: "",
        date_in: "",
        date_out: "",
      },
      // Other
      patients: "",
      beds: "",
    };
  },

  components: {
    Card,
    Form,
    ModalPatient,
  },

  methods: {
    formaterDate(val) {
      if (val.length == 4) {
        this.form.date_in = this.form.date_in + "-";
      }
      if (val.length == 7) {
        this.form.date_in = this.form.date_in + "-";
      }
    },

    formaterDateOut(val) {
      if (val.length == 4) {
        this.form.date_out = this.form.date_out + "-";
      }
      if (val.length == 7) {
        this.form.date_out = this.form.date_out + "-";
      }
    },

    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },
    async getBed() {
      let usedBed = await module.setTreeSelect("beds");
      let patient = await module.setTreeSelect("patients");

      this.beds = usedBed.data;
      this.patients = patient.data;
    },
    async getData() {
      this.form = await module.get("used-beds/" + this.$route.params.id);
      if (this.form == null) {
        // Redirect To List
        this.$router.push("/masters/beds/alloment");
      } else {
        this.form["_method"] = "put";
        this.display.patient_name = this.form.patient_name;
      }
    },
    async formOnSubmit() {
      // Make Request
      let response = await module.submit(
        this.form,
        "used-beds/" + this.form.id
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/beds/allotment");
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal", route: "" },
      { title: "Ubah Bangsal" },
    ]);
    this.getBed();
    this.getData();
  },
};
</script>

<style>
</style>