<template>
  <div>

    <b-form>

      <!-- Input Category -->
      <b-form-group
        id="input-group-category"
        label="Pilih Kategori Bangsal:"
        label-for="input-category"
      >
        <treeselect
          v-model="form.bed_category_id"
          :multiple="false"
          :options="categories"
          placeholder="Pilih Kategori Bangsal"
          required
        />
        <small class="text-danger">{{ error.bed_category_id }}</small>
      </b-form-group>

      <!-- Input Name -->
      <b-form-group
        id="input-group-name"
        label="Nama Bangsal:"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          required
          placeholder="Nama Bangsal"
        >
        </b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Input Description -->
      <b-form-group id="input-group-description">
        <label for="input-description">Deskripsi: <em class="text-muted">opsional</em></label>
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          rows="3"
          required
          placeholder="Deskripsi"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Button -->
      <b-button
        variant="primary"
        @click="formOnSubmit"
      >Simpan</b-button>
      <b-button
        class="ml-2"
        variant="default"
        @click="$router.push('/beds/list')"
      >
        Batal
      </b-button>

    </b-form>

  </div>
</template>

<script>
import module from '@/core/modules/CrudModule.js'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import validation from '@/core/modules/ValidationModule.js'

export default {

  data() {
    return {
      // option
      categories: [],
      error: {
        bed_category_id: "",
        name: "",
        description: "",
      },
    }
  },

  props: {
    form: Object,
    route: String,
  },

  components: {
    Treeselect,
  },

  methods: {
    async getCategory() {
      let response = await module.setTreeSelect('bed-categories')
      this.categories = response.data
      this.categories.unshift({ label: 'Pilih Kategori Bangsal', id: '', isDisabled: true })
    },

    async formOnSubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        this.$root.$emit('UpdateNewAvailableBed');
        if(response.success.title != '' && response.success.message != ''){
          Swal.fire(response.success.title, response.success.message, 'success')
        }else{
          Swal.fire("Gagal", "Data Gagal Disimpan", 'error')
        }
        this.$router.push('/beds/list')
      }
    }
  },

  mounted() {
    this.getCategory()
  }



}
</script>

<style>
</style>