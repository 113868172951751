<template>
  <div>

    <b-modal
      id="modal-patient"
      size="xl"
      title="Pilih Pasien"
      hide-footer
    >
      <!-- Tabs -->
      <b-tabs>
        <b-tab
          title="Daftar Pasien"
          active
        >
          <div class="mt-4">
            <Table
              purpose="modal"
              @choosePatient="chosenPatient"
            />
          </div>
        </b-tab>
        <b-tab title="Tambah Baru">
          <div class="mt-4">
            <Form
              :form="form"
              :route="'patients'"
              purpose="modal"
              @submitPatient="submitedPatient"
            />
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>

import Form from '@/component/patients/Form.vue'
import Table from '@/component/patients/TablePatients.vue'

export default {

  components: {
    Form,
    Table
  },

  methods: {

    chosenPatient(value) {
      this.$emit('chosenPatient', value)
    },

    submitedPatient(value) {
      this.$emit('submitedPatient', value)
    }

  },

  data() {
    return {
      form: {
        user_id: "",
        email: "",
        name: "",
        birt_place: "",
        birt_date: "",
        address: "",
        gender: "",
        phone: "",
        mobile_phone: "",
        parent_name: "",
        blood_type: "",
        id_card_number: "",
        allergy: "",
        photo: [],
        photo_name: "/images/default-profile.svg"
      }
    }
  }

}
</script>

<style>
</style>